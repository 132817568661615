import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import LogoArea from './Logo.style';

const Logo = ({ className, withLink, linkTo, title, src }) => {

  let navlink = (show, el)=>{
    if(!show)return el;
    return <NavLink to={linkTo}>{el} </NavLink>
  }

  return (
    <LogoArea className={className}>
      {navlink(withLink, <>

        {src && <img src={src} alt="Logo" />}
        {title && <h3>{title}</h3>}

      </>)}
    </LogoArea>
  );

  /*
  {withLink ? (
        <NavLink to={linkTo}>
          {src && <img src={src} alt="TripFinder." />}
          {title && <h3>{title} nig</h3>}
        </NavLink>
      ) : (
        <>
          {src && <img src={src} alt="TripFinder." />}
          {title && <h3>{title} nog</h3>}
        </>
      )}
   */
};

Logo.propTypes = {
  className: PropTypes.string,
  withLink: PropTypes.bool,
  src: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string,
};

export default Logo;
